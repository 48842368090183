$navigationItem : (
    length: 12,
    timing: 75ms,
    delayIn: 600ms
) !default;

#menu-container {
    &.anime- {
        &menu-in {
            animation-play-state: running;
            .navigation { 
                animation-direction: normal;
                animation-play-state: running;
                .menuList > li {
                    animation-play-state: running;
                    @for $i from 1 through map-get($navigationItem, length) {
                        &:nth-child(#{$i - 1}) {
                            $value : $i * map-get($navigationItem, timing);
                            animation-delay: $value + map-get($navigationItem, delayIn);
                        }
                    }
                }
            }
            .informations {
                > * {
                    animation-play-state: running;
                }
            }
        }
        &menu-out {
            $delay : map-get($navigationItem, length) * map-get($navigationItem, timing);
            $delayChildren : $delay + 100ms;
            animation-name: menuContainerReverse;
            animation-delay: $delayChildren + 250ms;
            .navigation {
                animation-delay: $delayChildren;
                animation-name: menuContainerNavigationReverse;
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                    animation-name: menuContainerNavigationReverseMobile;
                }
                animation-play-state: running;
                .menuList > li {
                    animation-name: fadeOutUp;
                    animation-play-state: running;
                    @for $i from 1 through map-get($navigationItem, length) {
                        &:nth-child(#{$i - 1}) {
                            $value : $delay - ($i * map-get($navigationItem, timing));
                            animation-delay: $value;
                        }
                    }
                }
            }
            .informations {
                > * {
                    animation-delay: $delayChildren;
                    animation-play-state: running;
                }
                > .identity {
                    animation-delay: $delayChildren + 50ms;
                    animation-name: fadeOutUp;
                }
                > .address {
                    animation-name: fadeOutDown;
                    animation-delay: $delayChildren - 50ms;
                }
            }
        }
    }

    .informations {
        > * {
            position: relative;
            animation-duration: 250ms;
            animation-delay: 500ms;
            animation-timing-function: ease-in;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-play-state: paused;
        }
        > .identity {
            animation-name: fadeInUp;
        }
        > .address {
            animation-name: fadeInDown;
            animation-delay: 600ms;
        }
    }
    
    .navigation {
        position: relative;
        animation-name: menuContainerNavigation;
        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
            animation-name: menuContainerNavigationMobile;
        }
        animation-duration: 250ms;
        animation-delay: 500ms;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
        animation-iteration-count: 1;
        animation-play-state: paused;
        .menuList {
            > li {
                animation-name: fadeInUp;
                animation-duration: 250ms;
                animation-timing-function: ease-in;
                animation-fill-mode: both;
                animation-iteration-count: 1;
                animation-play-state: paused;
                position: relative;
            }
        }
    }
    animation-name: menuContainer;
    animation-duration: 250ms;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    animation-play-state: paused;
}

@keyframes menuContainer {
    0% {
        opacity: 0;
        // width:0vw;
        // height: 0vh;
        // left: 100%;
    }
    100% {
        opacity: 1;
        // width:100vw;
        // height: 100vh;
        // left: 0%;
    }
}

@keyframes menuContainerReverse {
    100% {
        opacity: 0;
        // width:0vw;
        // height: 0vh;
        // left: 100%;
    }
    0% {
        opacity: 1;
        // width:100vw;
        // height: 100vh;
        // left: 0%;
    }
}

@keyframes menuContainerNavigation {
    0% {
        right: -55%;
    }
    100% {
        right: 0%;
    }
}

@keyframes menuContainerNavigationReverse {
    0% {
        right: 0%;
    }
    100% {
        right: -55%;
    }
}


@keyframes menuContainerNavigationMobile {
    0% {
        right: -100%;
    }
    100% {
        right: 0%;
    }
}

@keyframes menuContainerNavigationReverseMobile {
    0% {
        right: 0%;
    }
    100% {
        right: -100%;
    }
}

@keyframes fadeOutUp {
    100% {
        top: 100px;
        opacity: 0;
    }
    0% {
        top: 0px;
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        top: 100px;
        opacity: 0;
    }
    100% {
        top: 0px;
        opacity: 1;
    }
}

@keyframes fadeOutDown {
    100% {
        bottom: 100px;
        opacity: 0;
    }
    0% {
        bottom: 0px;
        opacity: 1;
    }
}

@keyframes fadeInDown {
    0% {
        bottom: 100px;
        opacity: 0;
    }
    100% {
        bottom: 0px;
        opacity: 1;
    }
}

