$anim-easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$cf-anim-time: 0.75s;
$cf-breakpoint-1: 769px;
$cf-color-green: $green;
$cf-font-weight-bold: 900;
$cf-color-black: #222222;
$cf-color-black-alpha04: $green;
$cf-color-black-alpha02: $green;
$cf-color-grey: #ebebeb;
$cf-color-white: white;

.nuzzo-theme .conversational-form {
  font-family: $font-title;
  font-style: normal;
}
.nuzzo-theme .conversational-form cf-chat scrollable::-webkit-scrollbar {
  width: 0px;
}
.nuzzo-theme .conversational-form cf-chat-response {
  padding-top: 0px;
  min-width: 200px;
}
@media (min-width: $cf-breakpoint-1) {
  .nuzzo-theme .conversational-form cf-chat-response {
    max-width: 83%;
  }
}
.nuzzo-theme .conversational-form cf-chat-response text {
  font-weight: 400;
  min-height: 42px;
  font-size: 16px;
  font-family: $font-title;
}
.nuzzo-theme .conversational-form cf-chat-response thumb {
  height: 25px;
  width: 25px;
}
.nuzzo-theme .conversational-form cf-chat-response thumb > p {
    background-color: $green;
    font-family: $font-title;
}
.nuzzo-theme .conversational-form cf-chat-response:not(:last-of-type) {
  margin-bottom: 20px;
}
.nuzzo-theme .conversational-form cf-chat-response.robot {
  padding-left: 48px;
}
.nuzzo-theme .conversational-form cf-chat-response.robot text {
  color: $cf-color-black;
  font-family: $font-title;
}
.nuzzo-theme .conversational-form cf-chat-response.robot text > p {
  border-radius: 4px 12px 12px 12px;
  background: $green;
  color: #fff;
  font-family: $font-title;
}
.nuzzo-theme
  .conversational-form
  cf-chat-response.robot
  text
  > p:not(:only-child):first-child {
  border-radius: 12px 12px 12px 4px;
}
.nuzzo-theme
  .conversational-form
  cf-chat-response.robot
  text
  > p:not(:only-child):last-child {
  border-radius: 4px 12px 12px 12px;
}
.nuzzo-theme .conversational-form cf-chat-response.robot thumb {
  background-color: $green;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: center center;
}
.nuzzo-theme .conversational-form cf-chat-response.user {
  padding-right: 48px;
}
.nuzzo-theme .conversational-form cf-chat-response.user text {
  color: $cf-color-white;
}
.nuzzo-theme .conversational-form cf-chat-response.user text > p {
  background: $cf-color-black;
  border-radius: 20px 4px 20px 20px;
  font-family: $font-title;
}
.nuzzo-theme .conversational-form cf-chat-response.user thumb {
  background-color: $cf-color-black;
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center center;
}
.nuzzo-theme .conversational-form cf-input input,
.nuzzo-theme .conversational-form cf-input textarea {
  border-radius: 0px;
  min-height: 57px;
  font-size: 14px;
  padding: 19px 60px 19px 30px;
  height: 56px;
  color: $dark;
  font-weight: 400;
  box-shadow: 0!important;
  font-family: $font-title;
}
@media (min-width: $cf-breakpoint-1) {
  .nuzzo-theme .conversational-form cf-input input,
  .nuzzo-theme .conversational-form cf-input textarea {
    font-size: 16px;
  }
}

// .cf-button.cf-checkbox-button[checked="checked"] cf-checkbox, .cf-button.cf-checkbox-button[selected="selected"] cf-checkbox
.nuzzo-theme .conversational-form cf-input input:not(:focus),
.nuzzo-theme .conversational-form cf-input textarea:not(:focus) {
  box-shadow: 0!important;
}
.nuzzo-theme .conversational-form cf-input-button {
  background: $green;
  height: 42px;
  width: 42px;
  border: none;
  border-radius: 0px;
  &:hover {
      background: #000;
  }
}
.nuzzo-theme .conversational-form cf-input-button .cf-icon-progress {
  background-image: url("data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0iMThweCIgaGVpZ2h0PSIxOXB4IiB2aWV3Qm94PSIwIDAgMTggMTkiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8ZyBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTM3Mi4wMDAwMDAsIC05NDUuMDAwMDAwKSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjIiPgogICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3NTEuMDAwMDAwLCA5MjcuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MjIuMDAwMDAwLCAxOS4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNOC4xNDAzNTA4OCwyLjEwOTE4MTE0IEw4LjE0MDM1MDg4LDE2LjA4MDkzODUiIGlkPSJMaW5lIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj48L3BhdGg+CiAgICAgICAgICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJSZWN0YW5nbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcuOTkzODAzLCA4LjA2ODc0NSkgcm90YXRlKC0zMTUuMDAwMDAwKSB0cmFuc2xhdGUoLTcuOTkzODAzLCAtOC4wNjg3NDUpICIgcG9pbnRzPSIyLjYyNTkxMzI4IDEzLjQ4Njk1OCAyLjYyNTkxMzI4IDIuNjUwNTMxMjEgMi42MjU5MTMyOCAyLjY1MDUzMTIxIDEzLjM2MTY5MjEgMi42NTA1MzEyMSI+PC9wb2x5bGluZT4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+");
  background-size: 18px 19px;
}
.nuzzo-theme .conversational-form cf-input-button.loading:after {
  cf-border: x solid $cf-color-green;
}
.nuzzo-theme .conversational-form cf-input-button .cf-icon-audio {
  background-image: url("data:image/png;charset=utf-8;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAYAAAGpAGuzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAtlJREFUeNpiUFJSYgDh////M7AwQMB/ZWVlBiYGJADiqACx/N27dxlByu7AZAACiAFqwCeQATA9vDA9KAb8BzGARv8HcRhBHJBpAAHECDIAZjGMAZJA0Y9u1mcg5kEWhJnLC5UEgf1QehFOOwACCFniKBBPBwouQXb8NiC2AuLFyJaDgCc2V+F0Lk4JkGsuYvMHCBhA6QNAQT8QgwUacCBd94DYAYRhUcKAJMkFFPyObDnM199g9gAEELLPMYIGCpYDTYnC5Y1/WHwUic/fjAwEABMDiYBkDaBg2gXErkCcCQom5BCBJQtYLCCH0h8gZgbiJ0Asi6YBFEvnoWxtFiSbQOA2lmAFgQBg0G7EFg/YFIOTKowNEEDYIi4GiKcB8WUgtgYqxvA0MliGFFFWUBsZ8QVrJLp1wNDZRmo8eJCqgZG+SWMQamBBSg64Ms9FYFzwQIu8L0zYkjIasIQmFxA4hqzBD1rEH0ASk4cWI9Oh/GxQ4tMCMq5CBQyB+AKaDY+BWAaI/wI1s4BsuAYqgqGS56F+2gd13n+oYlASZ0EOpXhQJkEy1RFU1UDZd5DzA3Ly3ggNKU6kfM2Inh+YsJR6yIXAf1jmJzviAAIMWxZFBvxAPAeIQ3DIrwHiFKCzP5JjgSe01iIGeAEt2U6qBa+BWIRIC94ALRAlNTOIkBDUIlTLbTTPzqMW0NWCrySY8xVfEXwLiFWRxDSB+AYQdwJxE5EWdEKrTQ0gdR1J/DbIBzPQFM+F0s1AbAvEH/AYDJKzBWayZjS9MDADlpPXAXEgksRbIDYB4gdIYupArAtlg5oSN6EFM8jlCkDmGSAWRlK/HigXhFxUFANxD5oLfkPbJlOhrSFkAArWbCDOAmJWNLkSoOG92MoiUAHfAcRlZCaaLiCuABr+n5jCjhVaXYYCsTMQs6HJ/wLivUC8GoiXAA39TU59gK/9B+snkV3h/CclbJArVrrmZAB+mc1ty+WBKAAAAABJRU5ErkJggg==");
  background-size: 12px 19px;
  mix-blend-mode: difference;
}
.nuzzo-theme .conversational-form cf-input-button cf-icon-audio-eq {
  border-color: $cf-color-black;
}
.nuzzo-theme .conversational-form .cf-button {
  color: $cf-color-black;
  border-color: #dddddd;
  transition: opacity $cf-anim-time ease-out,
    border-color $cf-anim-time ease-out,
    color $cf-anim-time ease-out;
  background: none !important;
  font-weight: $cf-font-weight-bold;
  line-height: 1.2;
}
.nuzzo-theme .conversational-form .cf-button > div {
  padding: 14px 24px;
}
.nuzzo-theme .conversational-form .cf-button cf-radio {
  background: $cf-color-black-alpha02;
}
.nuzzo-theme .conversational-form .cf-button.highlight,
.nuzzo-theme .conversational-form .cf-button:focus,
.nuzzo-theme .conversational-form .cf-button:hover,
.nuzzo-theme .conversational-form .cf-button[checked="checked"],
.nuzzo-theme .conversational-form .cf-button.selected {
  border-color: $green;
  color: $cf-color-black-alpha02;
}

.nuzzo-theme .conversational-form .cf-button[checked="checked"],
.nuzzo-theme .conversational-form .cf-button.selected {
    cf-checkbox {
        background-color: transparent!important;
        &:after {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13px' height='10px' viewBox='0 0 13 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg transform='translate%28-290.000000, -505.000000%29' fill='%239BBD1E'%3e%3cg transform='translate%2884.000000, 136.000000%29'%3e%3cg transform='translate%281.000000, 216.000000%29'%3e%3cg transform='translate%28189.000000, 139.000000%29'%3e%3cpolygon points='17.5 18.5 16 20 20 24 29 15.5 27.5 14 20 21'%3e%3c/polygon%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
        }
    }
}


.nuzzo-theme .conversational-form .cf-button.highlight,
.nuzzo-theme .conversational-form .cf-button:focus,
.nuzzo-theme .conversational-form .cf-button:hover{
    cf-checkbox {
        background-color: $green;
    }
}
.nuzzo-theme .conversational-form .cf-button.highlight cf-radio,
.nuzzo-theme .conversational-form .cf-button.highlight cf-radio,
.nuzzo-theme .conversational-form .cf-button.highlight cf-radio,
.nuzzo-theme .conversational-form .cf-button:focus cf-radio,
.nuzzo-theme .conversational-form .cf-button:focus cf-radio,
.nuzzo-theme .conversational-form .cf-button:focus cf-radio,
.nuzzo-theme .conversational-form .cf-button:hover cf-radio,
.nuzzo-theme .conversational-form .cf-button:hover cf-radio,
.nuzzo-theme .conversational-form .cf-button:hover cf-radio,
.nuzzo-theme .conversational-form .cf-button[checked="checked"] cf-radio,
.nuzzo-theme .conversational-form .cf-button[checked="checked"] cf-radio,
.nuzzo-theme .conversational-form .cf-button[checked="checked"] cf-radio,
.nuzzo-theme .conversational-form .cf-button.selected cf-radio,
.nuzzo-theme .conversational-form .cf-button.selected cf-radio,
.nuzzo-theme .conversational-form .cf-button.selected cf-radio {
  background: $cf-color-black;
}
.nuzzo-theme .conversational-form cf-list-button:after {
  background-image: none;
  background: black;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

#cf-context { 
//   width: 60vw; 
    display: none;
    &.active {
        display: block;
    }
    min-height: 60vh;
//   border: 1px solid $green;
    margin-top: $margin;
  .conversational-form {
      background: transparent;
      &:before {
        background: linear-gradient(to bottom,#fff 0,rgba(255,255,255,0) 100%);
      }
  }

  &.parent-has-greybg {
    .conversational-form {
        background: transparent;
        &:before {
          background: linear-gradient(to bottom,#F7F7F7 0,rgba(255,255,255,0) 100%);
        }
    }   
  }
}

#form {
  height: 0px;
  overflow: hidden;
}

.nuzzo-theme .conversational-form {
    cf-input input:active, cf-input input:focus, cf-input textarea:active, cf-input textarea:focus {
        
    }
}
