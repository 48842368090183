.page-template-tpl-order, .page-template-tpl-order-success, .page-template-tpl-order-admin {

    .error404 {
        h2 {
            margin-top: 80px;
            margin-bottom: 50px;
        }
        > .container {
            > div {
                padding-bottom: 80px;
                p {
                    margin-bottom: 25px;
                }
            }
        }

    }

    .table-show-cooking-false {
        .temperature span { visibility: hidden;  } 
    }

    #main-header {
        &.has-background {
            > nav {
                > ul {
                    > li {
                        a {
                            color: $white;
                        }
                    }
                }
                > #menu-revealer {
                    background: $white;
                    .cls-1 {
                        fill: $dark;
                    }
                    &:after {
                        background: $dark;
                    }
                }
            }
        }
        > nav {
            > ul {
                > li {
                    a {
                        color: $dark;
                    }
                }
            }
            > #menu-revealer {
                background: $dark;
                .cls-1 {
                    fill: $green;
                }
                &:after {
                    background: $green;
                }
            }
        }
        @media screen and ($gl-mq-width : map-get($gl-mq-list, sm)) {
            > nav {
                > ul {
                    > li {
                        a {
                            color: $white;
                        }
                    }
                }
                > #menu-revealer {
                    background: $white;
                    .cls-1 {
                        fill: $dark;
                    }
                    &:after {
                        background: $dark;
                    }
                }
            }
        }
    }

    #page-header {
        .ctaScroll {
            width: 200px;
            align-self: center;
            margin-bottom: $margin;
            margin-top: auto;
            text-align: center;
            color: $white;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            bottom: 0;
            transform: translateX(-50%);
            left: 50%;
    
            p {
                margin-bottom: $margin / 3;
                text-transform: uppercase;
                font-family: 'Josefin Sans', sans-serif;
            }
    
            img {
                width: 25px;
                transition: 0.3s ease-out;
            }
    
            &:hover {
                .square {
                    transform: translateY(5px);
                    transition: 0.3s ease-out;
                }
            }
    
        }
        section.picture {
            min-height: 100vh;
            display: flex;
            flex-direction: column;
            background-position: center center;
            background-size: cover;
            position: relative;
            justify-content: center;
            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                height: auto;
            }
            img {
                display: none;
            }
        }

        section.content {
            > div {
                padding: 80px 80px 30px;
            }
            h1 {
                font-family: $font-punch;
                font-size: em(40);
                position: relative;
                margin: $margin 0;
                margin-bottom: 40px;
        
                &::after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 2px;
                    background: $green;
                    position: absolute;
                    bottom: -15px;
                }
            }
        
            p {
                margin: $margin 0;
                color: $grey;
            }
        }
    }

    #content {
        > form {
            > .actions {
                text-align: center;
                .totalPrice {
                    color: $green;
                }
                .action {
                    margin-top: 25px;
                    input {
                        background: $green;
                        color: #fff;
                        cursor: pointer;
                        &:hover {
                            background: #000;
                            border-color: #000;
                        }
                    }
                }
            }
            > section {
                padding: 80px 0;
                &:after {
                    content: " ";
                    display: block;
                    height: 10px;
                    background: $green;
                    width: 200px;
                    max-width: 80%;
                    position: absolute;
                    margin-top: 75px;
                    margin-left: 5%;
                    z-index: 30;
                }
                &:nth-child(even) {
                    &:after {
                        margin-left: calc(90% - 200px);
                    }
                }
                &.dark {
                    background: #121212;
                    .options { label:before { border: 1px solid #121212; }}
                    h2, h3, p {
                        color: #fff;
                    }
                }
                > .container {
                    > h2 {

                    }
                    > article {
                        display: flex;
                        flex-wrap: wrap;
                        > .block {
                            width: 100%;
                            margin-bottom: 50px;
                            > .block-header {
                                margin-bottom: 20px;
                                > h3 {
                                    &:after {
                                        display: none;
                                    }
                                    margin: 0;
                                    font-size: 1.8em;
                                }
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                > .price {
                                    color: $green;
                                    font-size: 1.4em;
                                    font-weight: bold;
                                }
                            }
                            &.is-half {
                                max-width: 46%;
                                &:nth-child(even) {
                                    margin-left: 8%;
                                }
                                @media screen and (max-width: map-get($gl-mq-list, xs)) {
                                    &:nth-child(even) {
                                        margin-left: 0%;
                                    }
                                    max-width: 100%;
                                }
                            }
                            > .dishes {
                                > ul {
                                    padding: 0;
                                    margin: 0;
                                    list-style: none;
                                    > li.view-details {
                                        outline: 1px solid $green;
                                        &:before {
                                            content: "Votre choix";
                                            text-transform: uppercase;
                                            background: $green;
                                            color: #fff;
                                            position: absolute;
                                            padding: 3px 10px;
                                            margin-top: -10px;
                                            margin-left: -7px;
                                            font-size: 0.7em;
                                        }
                                        > div.details {
                                            display: block;
                                        }
                                    }
                                    > li {
                                        border: 2px solid rgba(0,0,0,0);
                                        margin-bottom: 12px;
                                        margin-top: 10px;
                                    }
                                    > li > div.details {
                                        background: rgba(#a0a0a0, 0.08);
                                        padding: 30px 50px;
                                        display: none;
                                        > .close {
                                            > a {
                                                color: $green;
                                                position: relative;
                                                top: -20px;
                                                left: -30px;
                                            }
                                        }
                                        > div {
                                            display: flex;
                                            > div {
                                                max-width: 46%;
                                                width: 100%;

                                                &:nth-child(odd) {
                                                    margin-right: 8%;
                                                }

                                                > h3 {
                                                    &:after {
                                                        display: none;
                                                    }
                                                    margin: 0;
                                                    font-size: 1.8em;
                                                }

                                                > p {
                                                    margin-bottom: 20px;
                                                    .count  {
                                                        font-weight: bold;
                                                        color: $green;
                                                        &.empty {
                                                            color: red;
                                                        }
                                                    }
                                                }

                                                > ul {
                                                    margin: 0px;
                                                    padding: 0px;
                                                    list-style: none;
                                                    > li {
                                                        display: flex;
                                                        margin-bottom: 20px;
                                                        > .qty {
                                                            width: 70px;
                                                            color: $green;
                                                            font-size: 1.2em;
                                                            input {
                                                                background: transparent;
                                                                padding: 3px 7px;
                                                                font-size: 0.75em;
                                                                border: 1px solid $green;
                                                                color: $grey;
                                                                box-shadow: none;
                                                                max-width: 30px;
                                                                &:focus {
                                                                    box-shadow: none;
                                                                    outline: 0;
                                                                    color: $green;
                                                                }
                                                            }
                                                        }
                                                        > .details {
                                                            > .infos {
                                                                color: $green;
                                                                span { 
                                                                    margin-right: 10px; padding-right: 10px; border-right: 1px solid $green; height: 10px; display: inline-block; vertical-align: middle; line-height: 10px; 
                                                                    &:last-child { border-right: none; }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            @media screen and (max-width: map-get($gl-mq-list, xs)) {
                                                flex-direction: column;
                                                > div {
                                                    max-width: 100%;
                                                    margin-bottom: 50px;
                                                    &:last-child {
                                                        margin: 0;
                                                    }
                                                    &:nth-child(odd) {
                                                        margin-right: 0%;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    > li > div.informations {
                                        display: flex;
                                        &:hover {
                                            background: rgba(#a0a0a0, 0.08);
                                        }
                                        // align-items: center;
                                        &:before {
                                            display: block;
                                            position: absolute;
                                            width: 10px;
                                            height: 2px;
                                            background: $green;
                                            content: " ";
                                            text-align: left;
                                            margin-top: -15px;
                                            display: none; 
                                        }
                                        &:first-child {
                                            // padding-top: 0px;
                                            &:before {
                                                display: none;
                                            }
                                        }
                                        padding: 15px 4px;
                                        > .labels {
                                            width: 70%;
                                            color: $grey;
                                            small {
                                                font-size: 0.80em;
                                            }
                                            > .options {
                                                margin-top: 5px;
                                                margin-bottom: 10px;
                                                input {
                                                    // border: 1px solid $green;
                                                    // border-radius: none;
                                                    position: absolute;
                                                    opacity: 0;
                                                    &:checked {
                                                        + label:before {
                                                            background: $green;
                                                        }
                                                    }
                                                }
                                                label {
                                                    margin-right: 20px;
                                                    cursor: pointer;
                                                    &:before {
                                                        display: inline-block;
                                                        height: 10px;
                                                        width: 10px;
                                                        outline: 1px solid $green;
                                                        content : " ";
                                                        margin-right: 7px;
                                                    }
                                                }
                                            }
                                            > .infos {
                                                color: $green;
                                                span { 
                                                    margin-right: 10px; padding-right: 10px; border-right: 1px solid $green; height: 10px; display: inline-block; vertical-align: middle; line-height: 10px; 
                                                    &:last-child { border-right: none; }
                                                }
                                            }
                                        }
                                        > .form-control {
                                            font-size: 1.2em;
                                            width: 30%;
                                            text-align: right;
                                            color: $green;
                                            .price {
                                                display: inline-block;
                                                margin-left: 15px;
                                                font-size: 1em;
                                                font-weight: bold;
                                            }
                                            .quantity {
                                                background: transparent;
                                                padding: 3px 7px;
                                                font-size: 0.75em;
                                                border: 1px solid $green;
                                                color: $grey;
                                                box-shadow: none;
                                                max-width: 30px;
                                                &:focus {
                                                    box-shadow: none;
                                                    outline: 0;
                                                    color: $green;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #cart {
        position: fixed; 
        display: none;
        top: 0;
        background: #fff;
        height: 100vh;
        width: 100%;
        z-index: 1000;
        &:after {
            display: none;
        }
        > div {
            display: flex;
            padding-top: 100px;
            padding-bottom: 100px;
            > .breadcrumb {
                width: 100%;
                max-width: 30%;
                @media screen and (max-width: 460px) {
                    display: none;
                }
                @for $i from 1 to 10 {
                    > ul[data-step="#{$i}"] {
                        > li:nth-child(#{$i}){
                            a { color: #121212; cursor: no-event; }
                        }
                    }
                }
                > ul {
                    text-align: right;
                    padding: 0;
                    margin: 0;
                    padding-right: 80px;
                    list-style: none;
                    a {
                        color: $grey;
                        font-size: 1.4em;
                        font-family: $font-punch;
                        pointer-events: none;
                    }
                    li {
                        margin-top: 15px;
                    }
                }
            }
            > .steps {
                width: 100%;
                max-width: 60%;
                @media screen and (max-width: 460px) {
                    max-width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                }
                @for $i from 1 to 6 {
                    &[data-current-step="#{$i}"] { > .step[data-step='#{$i}'] {
                        display: block;
                    }}
                }
                > .step {
                    &[data-step="4"] {
                        > .details {
                            height: calc(70vh - 200px);
                            overflow: auto;
                            margin-bottom: 40px;
                        }
                        > .recap {
                            display: flex;
                            > div {
                                width: 33%;
                            }
                            @media screen and (max-width: map-get($gl-mq-list, xs )) {
                                flex-direction: column;
                                > div {
                                    width: 100%;
                                }
                            }
                            h3 {
                                font-style: normal;
                                color: #121212;
                            }
                            p {
                                color: $grey;
                            }
                        }
                    }
                    display: none;
                    > .asks {
                        max-width: 95%;
                        margin-top: 35px;
                        .choice {
                            margin-bottom: 15px;
                        }
                        label {
                            display: block;
                            font-family: $font-title;
                            text-transform: uppercase;
                            &.inline {
                                display: inline;
                            }
                        }
                        
                        input[type=text], input[type=email], textarea {
                            border: 0;
                            display: block;
                            width: 100%;
                            border-bottom: 1px solid $green;
                            margin-bottom: 20px;
                        }
                    }
                    height: calc(100vh - 200px);
                    overflow: auto;
                    margin-bottom: 30px;
                    h3 {
                        font-size: 1.4em;
                        &:after {
                            display: none;
                        }
                        margin-bottom: 10px;
                        color: $green;
                        font-weight: bold;
                    }
                    .section {
                        // border-bottom: 1px solid $green;
                        width: 95%;
                        padding-bottom: 20px;
                        margin-bottom: 20px;
                    }
                    div.total {
                        width: 100%;
                        display: flex;
                        .warning {
                            width: 100%;
                            max-width: 70%;
                            color: red;
                            font-size: 0.8em;;
                        }
                        h3 {
                            margin-left: auto;
                            // text-align: right;
                            color: #121212;
                            span {
                                color: $green;
                            }
                        }
                    }
                    > .details {
                        height: calc(80vh - 200px);
                        overflow: auto;
                        margin-bottom: 40px;
                    }
                    table {
                        width: 100%;
                        thead {
                            th {
                                &:first-child {text-align: left; }
                                text-transform: uppercase;
                                font-family: $font-title;
                                padding-bottom: 10px;
                                small {
                                    font-size: 0.7em;
                                    text-transform: lowercase;
                                }
                            }
                        }
                        tbody {
                            tr {
                                td {
                                    padding: 4px 0;
                                    &.name {
                                        width: 60%;
                                    }
                                    &.temperature, &.price, &.qt, &.total {
                                        text-align: center;
                                    }
                                    &.qt, &.total, &.price {
                                        color: $green;
                                        font-weight: bold;
                                    }
                                }
                                &:nth-child(odd) {
                                    background: rgba(#a0a0a0, 0.08);
                                }
                            }
                        }
                    }
                }
                > .actions {
                    display: flex;
                    justify-content: space-between;
                    a {
                        color: #121212;
                        &:not(.button) {
                            color: $green;
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }
    }
}

.swal-button:not([disabled]):hover {

    background-color: #000;

}

.swal-button {
    background-color: $green;
    color: #fff;
    border: none;
    box-shadow: none;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px 24px;
    margin: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}
