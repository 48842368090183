
.login {
    background: $dark;
    background-image: url(/app/themes/spruce/static/images/menu.jpg);
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center center;
}

.login #nav a, .login #backtoblog a {
	color: $white!important;
	font-weight: bold;
	// background: $white;
	display: inline-block;
	padding: 5px 10px;
}

.login #nav a:hover, .login #backtoblog a:hover {
	color: $green;
	font-weight: bold;
}

.login.wp-core-ui .button {
	border-radius: 0px!important;
	box-shadow: none;
	border: none;
	background: $green!important;
	border-color: none!important;
	box-shadow: none!important;
	color: $white!important;
	text-decoration: none!important;
	text-shadow: none!important;
	text-transform: none!important;
    font-weight: bold;
    line-height: 1.4em!important;
}

#login h1 a, .login h1 a {
	background-image: url(/app/themes/spruce/static/images/logo.png);
height:70px;
width:300px;
background-size: contain;
background-repeat: no-repeat;
	padding-bottom: 30px;
}
