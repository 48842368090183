.page-template-tpl-contact {

    #main-header {
        > nav {
            > ul {
                > li {
                    a {
                        color: $dark;
                    }
                }
            }
            > #menu-revealer {
                background: $dark;
                .cls-1 {
                    fill: $green;
                }
                &:after {
                    background: $green;
                }
            }
        }
        @media screen and ($gl-mq-width : map-get($gl-mq-list, sm)) {
            > nav {
                > ul {
                    > li {
                        a {
                            color: $white;
                        }
                    }
                }
                > #menu-revealer {
                    background: $white;
                    .cls-1 {
                        fill: $dark;
                    }
                    &:after {
                        background: $dark;
                    }
                }
            }
        }
    }
    
    #contact-header {
        > section {
            // padding-top: 60px;
        }
    }

    section.form {
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-position: center center;
        background-size: cover;
        position: relative;
        justify-content: center;
        h2 {
            padding-top: 40px;
            font-weight: 100;
            font-style: italic;
            font-size: em(28);
        }

        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
            height: auto;
            margin-top: $margin * 2;
            margin-bottom: $margin * 2;
            h2 {
                font-weight: 100;
                font-style: italic;
                font-size: em(24);
            }
        }

        .wpcf7 {
            margin-top: 30px;
            label {
                font-family: $font-title;
                text-transform: uppercase;
                font-size: em(13);
            }
            .wpcf7-form-control {
                margin-bottom: 20px;
                width: 100%;
                border: none;
                border-bottom: 1px solid $green;
                font-size: 13px;
                font-family: $font-copy;
                &.wpcf7-textarea {
                    font-family: $font-copy;
                    border: 1px solid $green;
                    height: 70px;
                    font-size: em(14);
                    padding: 8px 0px;
                }
                &.wpcf7-submit {
                    transition: 0.2s all ease-in;
                    display: inline-block;
                    padding: ($margin/3.3) $margin $margin/3 $margin;
                    border: solid $green 1px;
                    text-transform: uppercase;
                    font-family: 'Josefin Sans', sans-serif;
                    font-size: em(14);
                    width: auto;
                    background: #fff;
                    cursor: pointer;
                    @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                        width: 100%;
                    }
                    &:hover {
                        background: $green;
                        color: $white;
                    }
                }
            }
        }
    }

    section.header {
        height: 100vh;
        display: flex;
        flex-direction: column;
        background-position: center center;
        background-size: cover;
        position: relative;
        justify-content: center;
        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
            height: auto;
        }
        .left {
            align-items: center;
            background: $black;
            box-sizing: border-box;
            padding: 50px !important;
            color: $white;
            position: relative;
            width: 70%;
            margin-left: 30%;
            position: relative;
            left: 5%;

            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                margin-left: 5%;
                left: 0;
                width: 90%;
                margin-top: 60px;
                padding: 50px 30px !important;
            }
    
            &::after {
                width: 70%;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
            }

            p {
                max-width: 80%;
            }
        }

        h1 {
            font-family: $font-punch;
            font-size: em(32);
            position: relative;
            margin: $margin 0;
    
            &::after {
                content: "";
                display: block;
                width: 10px;
                height: 2px;
                background: $green;
                position: absolute;
                bottom: -10px;
            }
        }
    
        p {
            margin: $margin 0;
        }
    }
}