section.map {
    #map {
        height: 400px;
        margin-top: 50px;
        width: 100%;

        &::after {
            bottom: -5px;
            left: 15%;
            z-index: 100;
        }

        &::before {
            top: -5px;
            right: 15%;
            z-index: 100;
        }
    }

}

.page-template-tpl-our-place {
    .header {
        .title {
            margin-bottom: 100px;
        }
    }
}

#addresses {
    .container {
        max-width: $container / 12 * 10;
    }
}

section.addresses {
    padding-top: 0;

    .address {
        margin: ($margin) 0;
        position: relative;
        padding-left: 30px;
        white-space: initial;

        h4 {
            font-size: em(18);
            color: $black;
            text-transform: uppercase;
            font-family: $font-title;
        }

        a {
            display: inline-block;
            font-size: em(14);
            color: $green;
            margin-bottom: $margin;
            font-family: 'Merriweather', serif;
            font-weight: 300;
            letter-spacing: 1px;

            &:hover {
                text-decoration: underline;
            }
        }

        p {
            font-size: em(14);
            color: $grey;
            font-family: 'Merriweather', serif;
        }

        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background-image: url("../images/ctascroll.svg");
            position: absolute;
            left: 0px;
            top: 0;
        }
    }

    @media screen and (max-width: 768px) {
        .left, .right {
            flex-direction: column;
            display: flex;
        }

        .address {
            display: inline-block;
            margin: ($margin * 1.5) auto;
            width: 100%;
        }
    }
}
