.page-template-tpl-tips {
    #howto {
        padding-top: 80px;
        h2 {
            margin-bottom: 0px;
            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                max-width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }
        overflow: hidden;
        .container {
            display: flex;
            align-items: stretch;
            > div {
                width: 80%;
                margin: 0 auto;
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                }
                padding-bottom: 25px;
            }
            &:before {
                content: " ";
                width: 1px;
                display: block;
                background: $green;
                position: relative;
                left: 50%;
                top: 50px;
                margin-top: 20px;
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                    left: 5%;
                }
            }
        }

        .segment {
            padding: 80px 0px 50px;
            &.is-grey {
                background: $offwhite;
                $separatorHeight: 10px;
                &:before, &:after {
                    width: 60px;
                    background: $green;
                    height: $separatorHeight;
                    content: " ";
                    position: absolute;
                    left: calc(50% - 30px);
                }
                &:before {
                    margin-top: -(80px + $separatorHeight/2);
                }
                &:after {
                    margin-top: (50px - $separatorHeight/2);
                }
            }
            &.is-last {
                .container::before { display: none; }
                h3 {
                    font-size: em(35);
                    small {
                        margin-bottom: 10px;
                    }
                }
                .ask-an-offer-description {
                    text-align: center;
                    position: relative;
                    top: -30px;
                }
            }
            &.is-white {
                background: $white;
            }
            h3 {
                position: relative;
                top: -45px;
                text-align: center;
                // @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                //     text-align: left;
                // }
                &:after {
                    display: none;
                }
                font-size: em(32);
                small {
                    display: block;
                    font-family: $font-copy;
                    font-style: normal;
                    font-size: 0.5em;
                    color: $grey;
                    margin-bottom: 30px;
                    &:before {
                        content: " ";
                        height: 2px;
                        width: 20px;
                        margin: 5px auto;
                        display: block;
                        background: $green;
                        // @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                        //     margin-left: 0;
                        // }
                    }
                }
            }
            // Tip item
            .item {
                &:before {
                    $segment: 14px;
                    height: $segment;
                    width: $segment;
                    background: $green;
                    content: " ";
                    display: block;
                    transform: rotate(45deg);
                    position: absolute;
                    left: calc(50% - 7px);
                    margin-top: 100px;
                    @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                        left: calc(10% - 8px);
                        margin-top: 60px;
                    }
                }
                width: 100%/8*3;
                &.even {
                    margin-left: 100%/8*5;
                }
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                    &, &.even {
                        width: 90%;
                        margin-left: 5%;
                    }
                    margin-bottom: 80px;
                }
                font-family: $font-copy;
                p, ul, li {
                    font-family: $font-copy;
                    color: $grey;
                    font-size: em(14);
                    strong, b {
                        color: $dark;
                        font-weight: 700;
                    }
                }
                .number {
                    font-family: $font-punch;
                    font-size: em(32);
                    font-weight: 700;
                    line-height: em(18);
                    display: block;
                    &::after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 2px;
                        background: $green;
                        margin: 8px 0 15px;
                    }
                }
                h4 {
                    font-family: $font-title;
                    font-size: em(16.5);
                    text-transform: uppercase;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    &.advice {
                        margin-top: 20px;
                        img {
                            position: relative;
                            top: 2px;
                        }
                        & + .advices {
                            border: 1px solid $green;
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
    }

    #faq {
        background: lighten($dark, 5%);
        padding: 80px 0;
        h2 {
            margin-bottom: 120px;
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            > li {
                margin-bottom: 30px;
                > h3 {
                    &:after {
                        display: none;
                    }
                    display: flex;
                    justify-content: space-between;
                    font-family: $font-title;
                    font-style: normal;
                    font-size: em(20);
                    margin: 0 0 15px 0;
                    cursor: pointer;
                    > span {

                    }
                    img {
                        transition: 0.2s all ease-in;
                        transform: rotate(180deg);
                    }
                    &.active {
                        img {
                            transform: rotate(0deg);
                        }
                        + div {
                            display: block;
                            opacity: 1;
                        } 
                    }
                }
                > div {
                    transition: 0.2s all ease-in;
                    transition-delay: 0.25s;
                    opacity: 0;
                    display: none;
                    color: $green;
                    p, ul, li ,td {
                        color: $green;
                        line-height: 1.8em;
                        margin-bottom: 20px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}