section.gallery {
    background: #000000;
   .slider {
        &.Big {
           height: 82vh;
           @media screen and (max-width: 550px) {
                height: 100vh;
            }
        }
        &.Small {
            @media screen and (max-width: 550px) {
                display: none;
            }
            height: 14vh;
            display: flex;
            .imageContainer {
            }
            .active {
                .imageContainer {
                    opacity: 1;
                }
            }
            .siema {
                > div {
                    > div {
                        opacity: 0.55;
                        &.active {
                            opacity: 1;
                        }
                    }
                }
            }
            > .control {
                width: 4vw;
                text-align: center;
                align-self: center;
                &.Prev {
                    svg {
                        transform: rotate(180deg);
                    }
                }
                .icon {
                    cursor: pointer;
                    svg {
                        width: 40%;
                        height: 40%;
                        .cls-1 {
                            fill: none;
                            stroke: #9bbd1e;
                            stroke-width: 1.7px;
                            transition: 0.2s ease-out;
                        }
                    }
        
                    &:hover {
                        svg .cls-1 {
                            stroke-width: 3px;
                            transition: 0.2s ease-in;
                        }
                    }
                }
            }
            > nav {
                width: 89vw;
                .imageContainer {
                    margin-right: 15px;
                    cursor: pointer;
                }
            }

            padding: 2vh 20px;
        }
        .siema {
            height: 100%;
            > div, > div > div, > div > div > div {
                height: 100%;
            }
            overflow: hidden;
            // .siemaBlock {
            //     height: 100%;
            // }
        }
        .imageContainer {
            height: 100%;
            img { visibility: hidden; }
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: 550px) {
                // background-size: contain;
            }
        }
   }
}
