section.error404 {
    text-align: center;
    padding-bottom: 0;

    ul {
        display: flex;
        justify-content: center;
        margin: 50px 0;
        margin-bottom: 100px;

        li {
            margin: 0px 10px;
        }
    }

    .imageContainer {
        height: 400px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        img {
            display: block;
            visibility: hidden;
            width: 100%;
            background: skyblue;
        }

        &::before {
            top: -5px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}