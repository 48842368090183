.quick-nav {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
    z-index: 500;

    li {
        text-align: right;
        a {
            padding: 10px;
            color: #121212;
            font-family: $font-title;
            font-size: em(12);
            transition: 0.3s ease-out;
            display: block;
            position: relative;

            &:hover {
                color: $green !important;
                transition: 0.3s ease-in;
            }

            &.isWhite {
                color: white;
            }

            &::after {
                content: "";
                width: 1px;
                background: #DEDEDE;
                position: absolute;
                top: 0;
                bottom: 0;
                right: -5px;
                transition: 0.3s ease-out;
            }

            &.active {
                color: $green !important;
                font-size: em(16);

                &::after {
                    width: 6px;
                    background: $green;
                    right: -8px;
                    transition: 0.3s ease-in;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
}