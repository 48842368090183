section.meeting, section.events {
    p {
        color: $grey;
        margin: $margin 0;

        &.price {
            color: $black;
            font-weight: bold;
            margin-bottom: 0;
        }
    }

    .container ul {
        li {
            align-items: center;
            padding: ($margin * 5) 0 !important;

            &:first-child {
                padding-top: 50px !important;
            }

            &:last-child {
                padding-bottom: 50px !important;
            }

            .containerText {
                margin-left: 8.3333%;
                margin-right: 16.6666%;
                position: relative;

                &::before {
                    content: "";
                    display: none;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: -1;
                    background: #F7F7F7;
                    width: 150%;
                    height: 130%;
                }

                @media screen and (max-width: 580px) {
                    margin-left: 0;
                    order: 1;

                    &::before {
                        transform: translateX(-50%) translateY(-55%);
                        height: 160%;
                    }
                }
            }

            .containerImage {
                transform: translateX(-50px);
                height: 350px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;

                img {
                    visibility: hidden;
                    width: 100%;
                }

                @media screen and (max-width: 768px) {
                    transform: translateX(0px);
                }

                @media screen and (max-width: 580px) {
                    order: 0;
                    margin-bottom: $margin;
                }

                &::after {
                    width: 60px;
                    bottom: -5px;
                    transform: translateX(-50%);
                    left: 40%;
                }
            }

            @media screen and (max-width: 580px) {
                margin: $margin * 2 0 !important;
            }
        }
    }
}

section.events {
    .container ul li:nth-child(even) {
        .containerImage {
            order: 0;
            margin-left: 16.6666%;
            transform: none;

            @media screen and (max-width: 1024px) {
                margin-left: 8.3333%;
            }

            @media screen and (max-width: 580px) {
                margin-left: 0;
            }
        }

        .containerText {
            order: 1;
            margin-left: 4.3333%;
            margin-right: 0;
            transform: translateY(50px);

            @media screen and (max-width: 768px) {
                transform: translateY(0px);
            }

            @media screen and (max-width: 580px) {
                margin-left: 0;
            }

            &::before {
                display: block;
            }
        }
    }
}

section.meeting {
    background: $black;
    color: white;

    .container ul li {
        .containerImage {
            order: 0;
            margin-left: 16.6666%;


            @media screen and (max-width: 768px) {
                margin-left: 8.3333%;
            }

            @media screen and (max-width: 580px) {
                margin-left: 0%;
            }
        }

        .containerText {
            order: 1;
            margin-right: 0;
        }
    }

    ul li:nth-child(odd) {
        .containerImage {
            margin-left: 16.6666%;
            order: 1;

            @media screen and (max-width: 580px) {
                margin-left: 0;
                margin-top: $margin;
                order: 0;
            }
        }

        .containerText {
            order: 0;
            margin-left: 4.3333%;
            margin-right: 0;
            transform: translateX(75px) translateY(50px);
            color: $white;

            &::before {
                display: block;
                background: #242424;
            }

            @media screen and (max-width: 580px) {
                margin-left: 0;
                order: 1;
                transform: none;
            }
        }
    }

    p.price {
        color: $white;
    }

    .buttonContainer {
        text-align: center;
    }
}

section.ref {
    text-align: center;
    ul {
        margin-top: $margin * 4;
        text-align: center;
        li {
            text-align: center;

            a {
                display: inline-block;
                // height: 100px;
                img {
                    max-width: 100%;
                    max-height: 50px;
                }
            }
        }
    }
}

section.numbers {
    text-align: center;
    background: $black;

    .number {
        font-size: em(120);
        font-family: 'Playfair Display', serif;
        color: white;
    }

    .title {
        color: $green;
        font-family: 'Josefin Sans', sans-serif;
        font-size: em(20);
    }

    &::before {
        top: -5px;
        right: 15%;
    }

    &::after {
        bottom: -5px;
        left: 15%;
    }
}

section.testimonys {
    &::after {
        width: 70px;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
    }

    .quote {
        text-align: center;
        font-size: em(14);
        color: $grey;
        padding: 30px 0;
        font-family: $font-copy;
        font-weight: normal;
        max-width: 65%;
        @media screen and ($gl-mq-width : map-get($gl-mq-list, lg)) {
            max-width: 70%;
        }
        @media screen and ($gl-mq-width : map-get($gl-mq-list, sm)) {
            max-width: 80%;
        }
        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
            max-width: 90%;
        }
        margin-left: auto;
        margin-right: auto;
    }

    .name {
        font-family: $font-copy;
        font-weight: bold;
        color: $dark;
        text-align: center;
        font-size: em(12);
    }

    .owl-dots {
        padding-top: 30px;
    }
}
