.menu-is-revealed {
    #main-header {
        &:before {
            opacity: 0;
        }
        > div {
            > a {
                opacity: 0;
            }
        }
        > nav {
            > ul {
                > li {
                    a {
                        color: $dark;
                    }
                }
            }
            > #menu-revealer {
                background: $dark;
                .cls-1 {
                    fill: $dark;
                }
                &:hover {
                    .cls-1 {
                        fill: $green;
                    }
                }
                &:after {
                    background: $green;
                }
            }
        }
    }
}

.positive {
    #main-header {
        > nav {
            > ul {
                > li {
                    a {
                        color: $dark;
                    }
                }
            }
            > #menu-revealer {
                background: $dark;
                .cls-1 {
                    fill: $green;
                }
                &:after {
                    background: $green;
                }
            }
        }
    }
}

#main-header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
    width: 100%;
    &:before {
        opacity: 1;
        transition: 0.15s all ease-in;
        position: absolute;
        background: $dark;
        content: "";
        height: 60px;
        width: 0%;
        margin-left: 100%;
        z-index: -1;
    }
    &.has-background {
        &:before {
            margin-left: 0%;
            width: 100%;
        }
    }
    > div {
        padding-top: 10px;
        padding-left: 40px;
        > a {
            transition: 0.2s all ease-in;
            opacity: 1;
            img {
                max-width: 145px;
                height: auto;
            }
        }
    }
    > nav {
        > ul {
            display: inline-block;
            vertical-align: middle;
            > li {
                display: inline-block;
                vertical-align: middle;
                font-family: $font-title;
                text-transform: uppercase;
                margin-right: 30px;
                > a {
                    color: #fff;
                    font-size: em(14);
                    transition: 0.2s all ease-in;
                    &:hover {
                        color:$green;
                    }
                }
            }
            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                display: none;
            }
        }
        > #menu-revealer {
            $segment : 60px;
            display: inline-block;
            vertical-align: middle;
            width: $segment;
            height: $segment;
            background: rgba(255,255,255,1);
            line-height: $segment;
            text-align: center;
            color: #000;
            text-transform: uppercase;
            font-family: $font-title;
            overflow: hidden;
            &:after {
                transition: 0.3s all ease-in;
                background: $dark;
                height: 0px;
                width: $segment/100*10;
                content: " ";
                display: block;
                border-radius: 50%;
                margin-top: 40px;
                margin-left: 10px;
                // position: absolute;
            }
            svg {
                // width: 16px;
                max-width: 15px;
                position: relative;
                top: 3px;
            }
            &:hover {
                &:after {
                    height: $segment*2;
                    width: $segment*2;
                    margin-top: -$segment*1.5;
                    margin-left: -$segment/1.5;
                }
            }
        }
    }
}

.backgroundImage {
    visibility: visible!important;
    max-width: 100vw;
    .slider {
        height: 100%;
        
        .siema {
            height: 100%;
            > div, > div > div, > div > div > div {
                height: 100vh;
            }
            overflow: hidden;
            // .siemaBlock {
            //     height: 100%;
            // }
        }
        .imageContainer {
            height: 100%;
            img { visibility: hidden; }
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            @media screen and (max-width: 550px) {
                // background-size: contain;
            }
        }
    }
}