body {
    line-height: 1.5;
    font-size: 14px;
}

html {
    /* Adjust font size */
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
}  

.wrapper {
    overflow: hidden;
}

h3 {
    font-family: 'Playfair Display', serif;
    font-size: em(32);
    font-style: italic;
}

h1, h2 {
    font-family: 'Playfair Display', serif;
    font-size: em(36);
    font-weight: 700;
    line-height: em(18);
}

h1 {
    font-weight: 400;
}

h1, h2, h3, h5 {
    position: relative;
    margin-bottom: $margin * 2;
    &.isBlack {
        color: $black;
    }

    &.isWhite {
        color: $white;
    }

    &::after {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        background: $green;
        position: absolute;
        bottom: -15px;
    }

    &.hasCenterLine {
        text-align: center;
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

a {
    text-decoration: none;
}

p {
    font-size: em(14);
    font-family: $font-copy;
    font-weight: 400;

    &.isWhite {
        color: $white;
    }

    &.isGrey {
        color: $grey;
        a {
            color: $grey;
        }
    }
}

.bigger {
    * {
        font-size: 1.05em;
    }
}

.mb-50 {
    margin-bottom: 50px!important;
}

div {
    &.isWhite {
        color: $white;
    }

    &.isGrey {
        color: $grey;
        a {
            color: $grey;
        }
    }
}

section {
    &.hasPadding {
        padding: 100px 0;

        @media screen and (max-width: 768px) {
            padding: 60px 0;
        }
    }


}

.container {
    width: $container;
    margin: 0 auto !important;

    @media screen and (max-width: 1100px) {
        width: 90%;
    }
}

.button {
    display: inline-block;
    padding: ($margin/3.3) $margin $margin/3 $margin;
    border: solid $green 1px;
    text-transform: uppercase;
    font-family: 'Josefin Sans', sans-serif;
    font-size: em(14);
    transition: 0.2s all ease-in;

    &:hover {
        transition: 0.2s all ease-in;
        background: $green;
    }

    &.isBlack {
        color: $black;
    }

    &.isWhite {
        color: $white;
    }

}

.buttonPlay {
    width: 40px;
    height: 40px;
    display: inline-block;
    
    svg {
        transition: 0.2s all ease-in;
        width: 100%;
        .cls-1 {
            fill: $green;
        }

        .cls-2 {
            fill: none;
        }
        .cls-3,
        .cls-4 {
            stroke: none;
        }

        .cls-4 {
            fill: $white;
        }
    }

    &:hover {
        svg {
            transform: scale(1.1);
            transition: 0.2s all ease-in;
        }
    }

    &.isBlack svg .cls-4 {
        fill: $black;
    }

}

.hasBeforeGreen {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 100;
        width: 240px;
        background: $green;
        height: 10px;
    }
}

.hasAfterGreen {
    position: relative;
    &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 100;
        width: 240px;
        background: $green;
        height: 10px;
    }
}

.is- {
    &visible { display: block; }
    &hide { display: none; }
}

.page-template-tpl-askanoffer {
    h1 {
        padding-top: 150px;
    }
    #cf-context {
        display: block;
        min-height: 65vh;
        margin-bottom: 50px;
    }
}