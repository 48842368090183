#menu-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
    width: 100vw;
    background: url(/app/themes/spruce/static/images/menu.jpg) center center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: stretch;
    display: none;
    transition: 0.2s all ease-in;
    &.active {
        display: flex;
    }

    @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
        overflow: auto;
        flex-direction: column;
    }

    .navigation {
        background: #fff;
        width: 50%;
        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
            width: 100%;
            min-height: 100vh;
        }
        &:before {
            content: "";
            background: #fff;
            transform: rotate(2deg);
            width: 80px;
            display: block;
            height: 110%;
            position: absolute;
            margin-left: -40px;
            margin-top: -5%;
            z-index: 1;
            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                display: none;
            }
        }
        > nav {
            display: flex;
            position: relative;
            z-index: 2;
            height: 100%;
            align-items: center;
            > ul {
                align-self: center;
                padding-left: 100px;
                @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                    padding-left: 0px;
                    text-align: center;
                    align-self: auto;
                    width: 100%;
                }
                > .menu-item {
                    &.for-mobile {
                        display: none;
                        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                            display: block;
                        }
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                    @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                        display: block;
                    }
                    font-family: $font-punch;
                    font-weight: 100;
                    a {
                        transition: 0.2s all ease-in;
                        color: rgba(0,0,0,0.5);
                        font-size: em(26);
                        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                            font-size: em(16);
                        }
                    }
                    &.current, &.current-menu-item, &.current-item, &:hover {
                        a {
                            color: rgba(0,0,0,1);
                            font-weight: 400;
                        }
                    }

                    &:after {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 2px;
                        background: $green;
                        margin: 22px 0 18px;
                        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                            margin: 15px auto 12px;
                        }
                    }
                }
            }
        }
    }
    .informations {
        width: 50%;
        @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
            width: 100%;
            display: none;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
            &.identity {
                padding: 100px 0px 0px 100px;
                img {
                    width: 320px;
                    max-width: 90%;
                    height: auto;
                }
                .baseline {
                    margin: 0;
                    color: #fff;
                    font-family: $font-title;
                    font-size: em(24);
                    font-weight: 300;
                    padding-left: 130px;
                    margin-top: 10px;
                    letter-spacing: 1px;

                }
            }
            &.address {
                padding: 0px 0px 100px 100px;
                font-family: $font-title;
                p {
                    font-family: $font-title;
                    margin: 0;
                }
                color: #fff;
                font-size: em(16);
                font-weight: 400;
                line-height: em(24);
                a {
                    color: #fff;
                }
            }
        }
    }
}
