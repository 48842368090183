@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:100,300,400|Merriweather:100,300,400,700|Playfair+Display:100,100i,400,400i,700");
@import "mixin";
@import "reset";

$browser-context: 14;

$green: #9BBD1E;
$black: #121212;
$white: #FFFFFF;
$dark: rgba(18,18,18,1);

$offwhite: #F7F7F7;

$grey: #888888;

// Sizes
$gl-gutter: 50px;
$margin: 20px;
$container: 1200px;

// Font-family helpers
$font-title: 'Josefin Sans', sans-serif;
$font-copy: 'Merriweather', serif;
$font-punch: 'Playfair Display', serif;

@import "gridlex/src/gridlex.scss";
@import "global";
@import "fragment";
@import "footer";
@import "home";
@import "landingpage";
@import "landingpageevent";
@import "service";
@import "team";
@import "ourPlace";
@import "error404";
@import "menu";
@import "menuMotion";
@import "header";
@import "conversationalForm";
@import "contact";
@import "tips";
@import "gallery";
@import "quickNav";
@import "login"; 
@import "order"; 


.page-template-tpl-menu {
	.sold-sentence {
		font-size: 1.25em;
		padding-bottom: 20px;
		text-align: center;
		border-bottom: 2px solid $green;
	}
	.alert.info {
		border: 2px solid red;
		margin-top: 20px;
		max-width: 80%;
		padding: 10px 20px;
		color: $white;
		display: none;
	}
    background: #000;
    main { padding-top: 150px; }
    &.menu-is-revealed {
        #main-header {
            background: transparent;
        }
    }
    #main-header {
        background: #fff;
	}

	.infoBlock {
		label {
			color: $green;
			font-weight: bold;
			display: block;
			text-transform: uppercase;
			font-family: $font-title;
		}
		&.w100 {
			input {
				width: 100%;
				max-width: 100%;
			}
		}
		input { 
			padding: 3px 0px;
			background: transparent;
			border: 1px solid $green;
			color: #fff;
		}

	}

	.section {
		margin-bottom: 50px;
	}
	h3 .input {
		position: relative;
		top: -7px;
	}
	.input {
		&:after {
			content: " x ";
			font-size: 15px;
			color: $green;
		}
		input { 
			max-width: 30px;
			padding: 3px 0px;
			background: transparent;
			border: 1px solid $green;
			color: #fff;
			text-align: center;
		}
	}

	input[type=submit] {
		border: none;
		cursor: pointer;
		display: inline-block;
		padding: 7px 15px;
		text-align: center;
		color: #FFF;
		text-decoration: none;
		background: $green;
		transition: 0.2s all ease-in;
		text-transform: uppercase;
		font-weight: bold;
		font-family: $font-title;
		font-size: 15px;
		&:hover {
			background: #fff;
			color: #000;
		}
		margin-bottom: 30px;
	}

	hr {
		border-color: $green;
		&.separator-sold {
			margin: 50px 0 20px;
		}
	}

	.number {
		font-weight: bold;
		color: $green;
	}

	.wpcf7-radio, .wpcf7-checkbox {
		margin-bottom: 30px;
		> span {
			display: block;
		}
	}

	table {
		width: 100%;
		font-size: 1.25em;
		td:last-child {
			font-weight: bold;
		}
		margin-bottom: 30px;
		
	}
    .actions {
        text-align: center;
        margin-top: 40px;
        margin-bottom: 40px;
        .Button {
            display: inline-block;
            padding: 7px 15px;
            text-align: center;
            color: #FFF;
            text-decoration: none;
            background: $green;
            transition: 0.2s all ease-in;
            text-transform: uppercase;
            font-weight: bold;
            font-family: $font-title;
            &:hover {
                background: #fff;
                color: #000;
            }
        }
    }
}


// Vars ---------------------------------------------------------------- //
$basicLightbox__background: rgba(0, 0, 0, .8) !default;
$basicLightbox__zIndex: 1000000 !default;
$basicLightbox__duration: .4s !default;
$basicLightbox__timing: ease !default;

// basicLightbox ------------------------------------------------------- //
.basicLightbox {

	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: $basicLightbox__background;
	opacity: .01; // Start with .01 to avoid the repaint that happens from 0 to .01
	transition: opacity $basicLightbox__duration $basicLightbox__timing;
	z-index: $basicLightbox__zIndex;
	will-change: opacity;

	&:before {
		cursor: pointer;
		content: "X";
		position: absolute;
		top: 30px;
		font-size: 3em;
		right: 40px;
		color: $white;
		@media screen and ($gl-mq-width : map-get($gl-mq-list, xs )) {
			font-size: 2em;
			right: 20px;
			top: 15px;
		}
	}

	&--visible {
		opacity: 1;
	}

	&__placeholder {
		max-width: 100%;
		transform: scale(.9);
		transition: transform $basicLightbox__duration $basicLightbox__timing;
		z-index: 1;
		will-change: transform;

		> img:first-child:last-child,
		> video:first-child:last-child,
		> iframe:first-child:last-child {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			max-width: 95%;
			max-height: 95%;
		}

		> video:first-child:last-child,
		> iframe:first-child:last-child {
			pointer-events: auto;
		}

		> img:first-child:last-child,
		> video:first-child:last-child {
			width: auto;
			height: auto;
		}

		> [data-basicLightbox] {
			// Always show content no matter what
			display: block !important;
		}
	}

	&--img &__placeholder,
	&--video &__placeholder,
	&--iframe &__placeholder {
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	&--visible &__placeholder {
		transform: scale(1);
	}

}

[data-basicLightbox] {

	// Always hide content no matter what
	display: none !important;

}
