.page-template-tpl-landingpage {
    .header {
        //
        h1.title {
            font-size: em(32);
            line-height: em(16);
            font-weight: 100;
            font-style: italic;
            margin-bottom: $margin * 2;
            &::after {
                bottom: -20px;
            }
        }
        // Adapt the size of the logo
        .custom-logo-link {
            width: 470px;
            margin-bottom: $margin * 2;
            display: block;
            img {
                width: 470px;
                height: auto;
            }
            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                margin-bottom: $margin;
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }

    #main-header {
        &.has-background {
            > div {
                > a {
                    opacity: 1;
                }
            }
        }
        > div {
            > a {
                opacity: 0;
            }
        }
    }

    &.menu-is-revealed {
        #main-header {
            > div {
                > a {
                    opacity: 0;
                }
            }
        }
    }

    .who {
        background: rgb(247, 247, 247);
        > div:first-child {
            > div {
                padding: 50px 0;
                max-width: 50%;
                margin: 0 0 0 40%;
                p {
                    margin-bottom: 25px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            } 
        }
        .picture {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            img {
                visibility: hidden;
            }
        }
    } 

    .contact {
        padding-bottom: 70px;
        text-align: center;
        h2 {
            &:after {
                position: relative!important;
                margin-left: auto;
                margin-right: auto;
            }
        }
        p {
            margin-bottom: 2em;
        } 
    }

    .content {


        section.events {
            .container ul li:nth-child(odd) {
                .containerText {
                    order: 0;
                    margin-left: 16.6666%;
                    transform: none;

                    @media screen and (max-width: 1024px) {
                        margin-left: 8.3333%;
                    }

                    @media screen and (max-width: 580px) {
                        margin-left: 0;
                    }
                }

                .containerImage {
                    order: 1;
                    margin-left: 4.3333%;
                    margin-right: 0;
                    transform: translateY(50px);

                    @media screen and (max-width: 768px) {
                        transform: translateY(0px);
                    }

                    @media screen and (max-width: 580px) {
                        margin-left: 0;
                    }

                    &::before {
                        display: block;
                    }
                }
            }
            
            .container ul li {
                .containerImage {
                    order: 0;
                    margin-left: 16.6666%;
                    transform: none;

                    @media screen and (max-width: 1024px) {
                        margin-left: 8.3333%;
                    }

                    @media screen and (max-width: 580px) {
                        margin-left: 0;
                    }
                }

                .containerText {
                    order: 1;
                    margin-left: 4.3333%;
                    margin-right: 0;
                    transform: translateY(50px);

                    @media screen and (max-width: 768px) {
                        transform: translateY(0px);
                    }

                    @media screen and (max-width: 580px) {
                        margin-left: 0;
                    }

                    &::before {
                        display: block;
                    }
                }
            }
        }

        section.meeting {
            background: $black;
            color: white;

            .container ul li {
                .containerImage {
                    order: 0;
                    margin-left: 16.6666%;


                    @media screen and (max-width: 768px) {
                        margin-left: 8.3333%;
                    }

                    @media screen and (max-width: 580px) {
                        margin-left: 0%;
                    }
                }

                .containerText {
                    order: 1;
                    margin-right: 0;
                }
            }

            ul li:nth-child(odd) {
                .containerImage {
                    margin-left: 7%;
                    order: 1;

                    @media screen and (max-width: 580px) {
                        margin-left: 0;
                        margin-top: $margin;
                        order: 0;
                    }
                }

                .containerText {
                    order: 0;
                    margin-left: 13.3333%;
                    margin-right: 0;
                    transform: translateX(75px) translateY(50px);
                    color: $white;

                    &::before {
                        display: block;
                        background: #242424;
                    }

                    @media screen and (max-width: 580px) {
                        margin-left: 0;
                        order: 1;
                        transform: none;
                    }
                }
            }

            p.price {
                color: $white;
            }

            .buttonContainer {
                text-align: center;
            }
        }
    }
}