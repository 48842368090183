footer {
    padding: 50px 0;
    background: #000000;
    color: $white;
    font-family: 'Josefin Sans', sans-serif;

    .custom-logo-link {
        display: block;
        margin-bottom: $margin;
        img {
            max-width: 100%;
            height: auto;
        }

        @media screen and (max-width: 580px) {
            width: 60%;
        }
    }

    p {
        font-family: 'Josefin Sans', sans-serif;
        margin: 5px 0;
    }

    small {
        color: $green;
    }

    a {
        display: inline-block;
        color: white;
        padding: 5px 0;
        margin: 5px 0;

        &:hover {
            text-decoration: underline;
        }
    }

    .facebook, .linkedin {
        a {
            display: flex;
            align-items: center;

            &::before {
                content: "";
                background-image: url("../images/facebookicon.svg");
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                width: 20px;
                height: 20px;
                display: inline-block;
                margin-right: 10px;
            }
        }
    }

    .linkedin {
        a::before {
            background-image: url("../images/linkedinicon.svg");
        }
    }

    .addresse {
        @media screen and (max-width: 580px) {
            line-height: 2;
            text-align: center;
        }
    }
}
