.page-template-tpl-home {
    .header {
        //
        h1.title {
            font-size: em(32);
            line-height: em(16);
            font-weight: 100;
            font-style: italic;
            margin-bottom: $margin * 2;
            &::after {
                bottom: -20px;
            }
        }
        // Adapt the size of the logo
        .custom-logo-link {
            width: 470px;
            margin-bottom: $margin * 2;
            display: block;
            img {
                width: 470px;
                height: auto;
            }
            @media screen and ($gl-mq-width : map-get($gl-mq-list, xs)) {
                margin-bottom: $margin;
                width: 100%;
                img {
                    width: 100%;
                }
            }
        }
    }

    #main-header {
        &.has-background {
            > div {
                > a {
                    opacity: 1;
                }
            }
        }
        > div {
            > a {
                opacity: 0;
            }
        }
    }

    &.menu-is-revealed {
        #main-header {
            > div {
                > a {
                    opacity: 0;
                }
            }
        }
    }
}

section.services {
    // background: $black;
    overflow: hidden;

    .buttonsContainer {
        display: flex;
        align-items: center;

        a {
            &:first-child {
                margin-right: 20px;
            }
        }
    }

    .right, .left {
        position: relative;
        z-index: 0;
    }

    .bg {
        position: absolute;
        width: 50%;
        z-index: -1;
    }

    .closeVideo {
        position: absolute;
        top: 40px;
        width: 30px;
        height: 30px;
        right: 42.5%;
        opacity: 0;
        z-index: -1;
        cursor: pointer;
        .cls-1 {
            fill: none;
        }

        .cls-2, .cls-4 {
            fill: #fff;
        }

        .cls-3, .cls-4 {
            stroke: none;
        }

        &.onWhite {
            left: 47.5%;
            .cls-2, .cls-4 {
                fill: $black;
            }
        }
    }

    .bgBlack {
        background: $black;
        top: 50%;
        right: -3%;
        transform-origin: left bottom;
        height: 110%;
        overflow: hidden;
        z-index: -1;
        transform: translateY(-50%) rotate(2deg);
        // transform: translateX(95%) translateY(-50%) rotate(2deg);
    }

    .bgWhite {
        width: 53%;
        height: 100%;
        top: 0;
        left: 0;
        transition: 0.6s;
    }

    p {
        margin: 30px 0;
    }

    .video {
        position: absolute;
        opacity: 0;
    }

    #video-right {
        right: 0;
        transform: translateY(3%) rotate(-2deg);
        width: 100%;
        height: 90%;
    }

    #video-left {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        width: 95%;
    }

    &::after {
        bottom: -5px;
        right: 15%;
    }

    @media screen and (max-width: 1024px) {
        .buttonsContainer {
            flex-direction: column;

            a {
                &:first-child {
                    margin-right: 0;
                    margin-bottom: $margin;
                }
            } 
        }
    }

    @media screen and (max-width: 768px) {
        padding: 0;

        .closeVideo {
            top: 51%;
            right: 5%;
            left: auto;

            &.onWhite {
                top: 1%;
                left: auto;
            }
        }

        #video-left, #video-right {
            top: 50%;
            transform: translateY(-50%);
            height: 80%;
        }

        #video-left {
            width: 100%;
        }

        &::after {
            left: 50%;
            transform: translateX(-50%);
        }

        .bgBlack, .bgWhite {
            width: 100%;
            height: 50%;
            transform: none;
        }

        .bgBlack {
            top: auto;
            bottom: 0;
            right: auto;
        }
        
        .bgWhite {
            left: auto;
            top: 0;
            bottom: auto;
        }

        .left, .right {
            padding: 100px 0 !important;
            text-align: center;

            h2 {
                &::after {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }

            .buttonsContainer {
                justify-content: center;
            }
        }
    }
}

section.galery {
    .container {
        justify-content: center;

        @media screen and (max-width: 580px) {
            width: 100%;
        }
    }

    .containerSliders {
        position: relative;

        @media screen and (max-width: 580px) {
            margin-bottom: $margin * 3;
        }
    }

    .js_frame, .js_frame_text {
        overflow: hidden;
        position: relative;
        white-space: nowrap;
    }

    .js_slides, .js_slides_text {
        display: inline-block;
        width: 100%;
    }

    .js_slide, .js_slide_text {
        position: relative;
        display: inline-block;
        height: 500px;
        width: 100%;
        background: skyblue;
    }

    .js_slider_image {
        width: 80%;
        margin: 0 auto;
        margin-top: 100px;
        position: relative;

        @media screen and (max-width: 580px) {
            margin-top: $margin;
            width: 100%;
        }

        .js_next, .js_prev {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;

            svg {
                width: 30px;
                height: 30px;

                .cls-1 {
                    fill: none;
                    stroke: #9bbd1e;
                    stroke-width: 1.7px;
                    transition: 0.2s ease-out;
                }
            }

            &:hover {
                svg .cls-1 {
                    stroke-width: 3px;
                    transition: 0.2s ease-in;
                }
            }
        }

        .js_prev {
            left: -50px;

            svg {
                transform: rotate(180deg);
            }
        }

        .js_next {
            right: -50px;
        }
    }

    .dotsNav {
        background: $black;
        padding: 30px;
        padding-right: 60px;
        color: $white;
        position: absolute;
        left: 0%;
        top: -40px;
        width: 300px;
        text-align: right;
        z-index: 100;

        p {
            font-size: em(22);
            margin-bottom: $margin;
            // min-height: 120px;
            white-space: initial;
        }

        a.button {
            font-size: em(14);
        }

        .js_slide_text {
            background: none;
            height: auto;
            width: 100.5%;
        }

        .dots {
            position: absolute;
            top: 30px;
            right: 20px;
            display: flex;
            flex-direction: column;

            li {
                margin: 7px 0;
                border: solid $green 1px;
                border-radius: 100px;
                width: 7px;
                height: 7px;
                transition: 0.2s ease-out;
                cursor: pointer;

                &:hover {
                    background: $green;
                    transition: 0.2s ease-in;
                }

                &.active {
                    background: $green;
                }
            }
        }

        @media screen and (max-width: 580px) {
            width: 90%;
            box-sizing: border-box;
            top: auto;
            bottom: -130px;
            left: 50%;
            transform: translateX(-50%);
            padding-right: 30px;
            text-align: center;

            p {
                margin-bottom: 0;
            }

            .dots {
                position: static;
                flex-direction: row;
                justify-content: center;
                margin: 20px 0;

                li {
                    margin: 0 10px;
                }
            }

            .button {
                margin-top: 20px;
            }
        }
    }
}
