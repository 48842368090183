@keyframes fadeInLeft {
    0% {
        opacity: 0;
        top: -20px;
    }
    100% {
        opacity: 1;
        top: 0px;
    }
}

section.header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 900;

    .backgroundImage {
        visibility: hidden;
        position: absolute;
        background-position: center center;
        background-size: cover;
        z-index: -1;
    }

    .container {
        flex: 1;
        align-items: center;

        .left {
            // display: none;
            // animation-name: headerBlackBox;
            // animation-duration: 250ms;
            // animation-delay: 0s;
            // animation-timing-function: ease-in;
            // animation-fill-mode: both;
            // animation-iteration-count: 1;
            // animation-play-state: running;
            background: $black;
            box-sizing: border-box;
            padding: 50px !important;
            color: $white;
            position: relative;

            // opacity: 0;
            // transform: translateX(-50%);
            &.unleashTheFury {
                > * {
                }
            }
            a {
                color: #ffffff;
                text-decoration: underline;
            }
            > * {
                animation-play-state: running;
                animation-name: fadeInLeft;
                animation-duration: 250ms;
                animation-delay: 500ms;
                animation-timing-function: ease-in;
                animation-fill-mode: both;
                animation-iteration-count: 1;
                // animation-play-state: paused;
                position: relative;
                @for $i from 1 through 5 {
                    &:nth-child(#{$i - 1}) {
                        $value : (150ms * $i) ;
                        animation-delay: $value;
                    }
                }
            }

            &::after {
                width: 70%;
                bottom: -5px;
                left: 50%;
                transform: translateX(-50%);
            }
           

            p {
                // max-width: 80%;
            }
        }
    
        .imageContainer {
            width: 165%;
        }
    
        h1 {
            font-family: $font-punch;
            font-size: em(32);
            position: relative;
            margin: $margin 0;
    
            &::after {
                content: "";
                display: block;
                width: 10px;
                height: 2px;
                background: $green;
                position: absolute;
                bottom: -10px;
            }
        }
    
        p {
            margin: $margin 0;
        }
    }

    .ctaScroll {
        width: 200px;
        align-self: center;
        margin-bottom: $margin;
        margin-top: auto;
        text-align: center;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 0;
        transform: translateX(-50%);
        left: 50%;

        p {
            margin-bottom: $margin / 3;
            text-transform: uppercase;
            font-family: 'Josefin Sans', sans-serif;
        }

        img {
            width: 25px;
            transition: 0.3s ease-out;
        }

        &:hover {
            .square {
                transform: translateY(5px);
                transition: 0.3s ease-out;
            }
        }

    }

    @media screen and (max-height: 700px) {
        height: 700px;
    }
    
    @media screen and (max-width: 768px) {
        height: auto;
        .container .left {
            padding: 30px !important;
        }
    }

    @media screen and (max-width: 580px) {
        background-image: none !important;
        background: $black;
        .backgroundImage {
            position: static;
            visibility: initial;
            z-index: 0;
            height: 400px;
        }

        .container {
            .left {
                &::after {
                    bottom: auto;
                    top: -5px;
                }

                .imageContainer {
                    width: 100%;
                }
            }
        }

        .ctaScroll {
            position: static;
            transform: none;
        }
    }
}

section.askOffer {
    background: $offwhite;
    .container {
        justify-content: center;

        .content {
            text-align: center;

            p {
                margin: $margin 0;
            }
        }
    }

    &::after {
        bottom: -5px;
        left: 15%;
    }

    @media screen and (max-width: 768px) {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

section.slider {
    .js_frame {
        overflow: hidden;
        position: relative;
        white-space: nowrap;
    }
    
    .js_slides {
        display: inline-block;
        width: 100%;
    }
    
    .js_slide {
        position: relative;
        display: inline-block;
        width: 100%;
    }

    .dotsNav {
        margin-bottom: $margin*2;
        position: relative;
        ul {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                font-size: em(23);
                color: $black;
                opacity: 0.25;
                font-style: italic;
                font-family: $font-punch;
                // margin: 0 15px;
                transition: 0.3s ease-out;
                cursor: pointer;
                position: relative;
                // text-align: center;



                &::after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 2px;
                    background: $green;
                    position: absolute;
                    bottom: -5px;
                    opacity: 0;
                    transition: 0.3s ease-out;
                }

                &:hover {
                    opacity: 1;
                    transition: 0.3s ease-out;
                }

                &.active {
                    opacity: 1;
                    &::after {
                        transition: 0.3s ease-out;
                        opacity: 1;
                    }
                }

                @media screen and (max-width: 1024px) {
                    font-size: em(20);
                }
                @media screen and (max-width: 768px) {
                    flex-basis: 40%;
                    max-width: 40%;
                    margin: 10px 0;
                    text-align: center;

                    &::after {
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }

                @media screen and (max-width: 580px) {
                    font-size: em(18);
                }
            }

            @media screen and (max-width: 768px) {
                flex-flow: row wrap;
            }
        }
    }

    .js_next, .js_prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        display: none;

        svg {
            width: 25px;
            height: 25px;

            .cls-1 {
                fill: none;
                stroke: $green;
                stroke-width: 1.7px;
                transition: 0.2s ease-out;
            }

            @media screen and (max-width: 768px) {
                width: 20px;
                height: 20px;
            }
        }

        &:hover {
            svg .cls-1 {
                stroke-width: 3px;
                transition: 0.2s ease-in;
            }
        }
    }

    .js_prev {
        left: 0px;

        svg {
            transform: rotate(180deg);
        }
    }

    .js_next {
        right: 0px;
    }

}