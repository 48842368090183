section.team {
    .js_slide {
        ul {
            margin: 0 !important;
            width: 100%;
            margin-top: $margin!important;

            li {
                padding: 40px !important;
                padding-top: 0 !important;
                text-align: center;
            }

            .containerImage {
                margin-bottom: $margin;
                img {
                    background: skyblue;
                    display: block;
                    height: 300px;
                    width: 100%;
                }
            }

            h4 {
                font-family: 'Playfair Display', serif;
                font-style: italic;
                color: $black;
                font-size: em(24);
            }

            h5 {
                font-family: 'Josefin Sans', sans-serif;
                font-size: em(14);
                text-transform: uppercase;
                color: $grey;
                margin-bottom: $margin / 2;
                + p {
                    margin-top: 30px;
                }
            }

            p {
                font-family: 'Merriweather', serif;
                font-size: em(16);
                color: $black;
            }
        }
    }
}

#team {
    .container {
        max-width: $container / 12 * 10;
    }
} 